<template>
  <drawer-layout
    :title="setTitle"
    :show="open"
    :isForm="isForm"
    @show="show"
    :maskClosable="false"
    destroy-on-close
    :permission="
      curRow.type == '特殊字体' &&
      userInfo.positions &&
      userInfo.positions.some(item => item.name == '翻译' || item.name == '校对' || item.name == '终审')
        ? 'production:termtemplate:back:noedit'
        : 'production:termtemplate:back:edit'
    "
    :btnLoading="btnLoading"
    @closed="closed"
    @save="save"
    @edit="edit"
  >
    <div slot>
      <info-item :label-width="labelWidth" label="章节序号">{{ curRow.chapterNo }}</info-item>
      <h4 v-if="curRow.type != '特殊字体'" style="font-weight:700">翻译、校对、终审填写：</h4>
      <info-item v-if="curRow.type != '特殊字体'" :label-width="labelWidth" label="文本框">{{
        textAreaList.length && curRow.textAreaId ? textAreaList.find(item => item.id == curRow.textAreaId)?textAreaList.find(item => item.id == curRow.textAreaId).label : '':''
      }}</info-item>
      <info-item :label-width="labelWidth" label="原文图片">
        <div>
          <img
            v-if="curRow.originImgUrl"
            @click="handleImgClick"
            :src="curRow.originImgUrl"
            style="max-width:100%;max-height:180px;cursor:pointer"
            alt="avatar"
          />
        </div>
      </info-item>
      <info-item
        v-if="curRow.type != '特殊字体'"
        :label-width="labelWidth"
        label="原文"
        style="white-space: pre-wrap;word-wrap: break-word;"
        >{{ curRow.originContent }}</info-item
      >
      <info-item
        v-if="curRow.type != '特殊字体'"
        :label-width="labelWidth"
        label="译文"
        style="white-space: pre-wrap;word-wrap: break-word;"
        >{{ curRow.processedContent }}</info-item
      >
      <h4 v-if="curRow.type != '特殊字体'" style="font-weight:700">制作、监制填写：</h4>
      <info-item :label-width="labelWidth" label="文件名">{{ curRow.fileName }}</info-item>
      <info-item :label-width="labelWidth" label="制作后图片">
        <div>
          <img
            v-if="curRow.processedImgUrl"
            :src="curRow.processedImgUrl"
            @click="handleImgClick"
            style="max-width:100%;max-height:180px;cursor:pointer"
            alt="avatar"
          />
        </div>
      </info-item>
      <info-item :label-width="labelWidth" label="字体">{{ curRow.typeface }}</info-item>
    </div>
    <div :style="backCss" slot="form">
      <a-form-model ref="form" style="padding-bottom:65px" :model="formData" :rules="rules" v-bind="layout">
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="章节序号" prop="chapterNo">
          <a-select
            @change="chapterChange"
            style="width:100%"
            v-model="formData.chapterNo"
            :disabled="
              (userInfo.positions &&
                userInfo.positions.some(item => item.name == '翻译' || item.name == '校对' || item.name == '终审')) ||
              isAdmin ||
              parent.isTongChou ||
              addType == '特殊字体'
                ? false
                : true
            "
            show-search
            placeholder="请选择章节"
          >
            <a-select-option v-for="chapter in chapterList" :key="chapter.sequenceNo" :value="chapter.sequenceNo">
              {{ chapter.sequenceNo }}
            </a-select-option>
            <a-select-option
              v-if="
                isEdit && chapterList.every(chapter => chapter.sequenceNo != formData.chapterNo) && formData.chapterNo
              "
              :value="formData.chapterNo"
            >
              章节已删除
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!--  v-if="
            (userInfo.positions &&
              userInfo.positions.some(item => item.name == '翻译' || item.name == '校对' || item.name == '终审')) ||
              isAdmin ||
              parent.isTongChou
          " -->
        <template>
          <h4 v-if="formData.type == '背景文字' || addType == '背景文字'" style="font-weight:700">
            翻译、校对、终审填写：
          </h4>
          <a-form-model-item
            v-if="formData.type == '背景文字' || addType == '背景文字'"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="文本框"
            prop="chapterNo"
          >
            <a-spin :spinning="selectLoading">
              <a-select
                style="width:100%"
                dropdownClassName="textArea-select"
                @change="textAreaChange"
                v-model="formData.textAreaId"
                :disabled="
                  userInfo.positions && userInfo.positions.some(item => item.name == '制作' || item.name == '监制')
                "
                show-search
                option-filter-prop="label"
                placeholder="请选择文本框"
              >
                <a-select-option
                  :label="textArea.label"
                  v-for="textArea in textAreaList"
                  :key="textArea.id"
                  :value="textArea.id"
                >
                  {{ textArea.label }}
                </a-select-option>
              </a-select>
            </a-spin>
          </a-form-model-item>
          <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="原文图片" prop="chapterNo">
            <a-spin :spinning="wangeditorLoading">
              <a-upload-dragger
                name="file"
                accept=".jpg,.png"
                :showUploadList="false"
                :action="ossUploadUrl"
                :disabled="
                  userInfo.positions &&
                    userInfo.positions.some(item => item.name == '制作' || item.name == '监制') &&
                    (addType == '背景文字' || formData.type == '背景文字')
                "
                :data="ossUploadData"
                :custom-request="handleUpload"
                @change="handleUploadChange"
              >
                <div v-if="!formData.originImgUrl">
                  <p class="ant-upload-drag-icon">
                    <a-icon type="inbox" />
                  </p>
                  <p class="ant-upload-text">
                    拖拽或点击上传
                  </p>
                  <p class="ant-upload-hint">
                    支持JPG、PNG
                  </p>
                </div>
                <img v-else style="max-width:300px;max-height:117px" :src="formData.originImgUrl" alt="" />
                <a-icon
                  v-if="
                    formData.originImgUrl &&
                      ((userInfo.positions &&
                        userInfo.positions.every(item => item.name != '制作' && item.name != '监制')) ||
                        addType == '特殊字体' ||
                        formData.type == '特殊字体')
                  "
                  @click.stop="clearUrl"
                  type="close-circle"
                />
              </a-upload-dragger>
              <Editor
                ref="editor"
                v-if="
                  editorShow &&
                    ((userInfo.positions &&
                      userInfo.positions.every(item => item.name != '制作' && item.name != '监制')) ||
                      addType == '特殊字体' ||
                      formData.type == '特殊字体')
                "
                class="img-editor"
                v-model="originImgUrl"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
                @customPaste="customPaste"
              />
            </a-spin>
          </a-form-model-item>
          <a-form-model-item
            v-if="formData.type == '背景文字' || addType == '背景文字'"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="原文"
            prop="originContent"
          >
            <a-textarea :disabled="true" v-model="formData.originContent" :rows="5" />
          </a-form-model-item>
          <a-form-model-item
            v-if="formData.type == '背景文字' || addType == '背景文字'"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="译文"
            prop="processedContent"
          >
            <a-textarea :disabled="true" v-model="formData.processedContent" :rows="5" />
          </a-form-model-item>
        </template>
        <template
          v-if="
            (userInfo.positions && userInfo.positions.some(item => item.name == '制作' || item.name == '监制')) ||
              isAdmin ||
              parent.isTongChou
          "
        >
          <h4 v-if="formData.type == '背景文字' || addType == '背景文字'" style="font-weight:700">制作、监制填写：</h4>
          <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="文件名" prop="fileName">
            <a-input v-model="formData.fileName" placeholder="请输入文件名" />
          </a-form-model-item>
          <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="制作后图片" prop="chapterNo">
            <a-spin :spinning="wangeditorLoading2">
              <a-upload-dragger
                name="file"
                accept=".jpg,.png"
                :showUploadList="false"
                :action="ossUploadUrl"
                :data="ossUploadData"
                :custom-request="handleUpload"
                @change="handleUploadChange1"
              >
                <div v-if="!formData.processedImgUrl">
                  <p class="ant-upload-drag-icon">
                    <a-icon type="inbox" />
                  </p>
                  <p class="ant-upload-text">
                    拖拽或点击上传
                  </p>
                  <p class="ant-upload-hint">
                    支持JPG、PNG
                  </p>
                </div>
                <img v-else style="max-width:300px;max-height:117px" :src="formData.processedImgUrl" alt="" />
                <a-icon v-if="formData.processedImgUrl" @click.stop="clearUrl2" type="close-circle" />
              </a-upload-dragger>
              <Editor
                ref="editor"
                class="img-editor"
                v-if="editorShow"
                v-model="processedImgUrl"
                :defaultConfig="editorConfig2"
                :mode="mode"
                @onCreated="onCreated2"
                @customPaste="customPaste2"
              />
            </a-spin>
          </a-form-model-item>
          <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="字体" prop="tyoeface">
            <a-input v-model="formData.typeface" placeholder="字体" />
          </a-form-model-item>
        </template>
      </a-form-model>
    </div>
    <playvideo-modal ref="videoModal" />
  </drawer-layout>
</template>

<script>
import { DrawerMixins } from '@/mixins/DrawerMixins'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { postAction, putAction, v1getAction, getAction } from '@/api/manage'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import playvideoModal from '@/components/playvideo-modal'
import { IEditorConfig } from '@wangeditor/editor'
export default {
  props: {
    addType: {
      type: String,
      default: ''
    },
    parent: {
      type: Object,
      default: {}
    },
    userInfo: {
      type: Object,
      default: () => ({})
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isNewForm: {
      type: Boolean,
      default: true
    }
  },
  components: { Editor, Toolbar, playvideoModal },
  mixins: [DrawerMixins, UploadFileToOssMixins],
  data() {
    return {
      rules: {},
      labelCol: {
        span: 7
      },
      formData: {},
      wrapperCol: {
        span: 16
      },
      editorConfig: { placeholder: '拖拽或将图片粘贴到这里', MENU_CONF: { uploadImage: null } },
      editorConfig2: { placeholder: '拖拽或将图片粘贴到这里', MENU_CONF: { uploadImage: null } },
      editor: null,
      editor2: null,
      mode: 'default',
      wangeditorLoading: false,
      wangeditorLoading2: false,
      originImgUrl: '',
      processedImgUrl: '',
      isChange: false,
      isChange2: false,
      chapterList: [],
      textAreaList: [],
      labelWidth: '120',
      selectLoading: false,
      editorShow: false
    }
  },
  computed: {
    backCss() {
      let color = this.$store.state.app.color
      return {
        '--theme-color': color
      }
    }
  },

  mounted() {},
  watch: {
    originImgUrl() {
      if (this.formData.originImgUrl) {
        this.editor.undo()
        if (this.isChange) {
          this.editor.dangerouslyInsertHtml(
            `<img src='${this.formData.originImgUrl}' style='width:356px;height:auto' />`
          )
        } else {
          this.editor.clear()
          if (this.curRow.originImgUrl) {
            this.formData.originImgUrl = this.curRow.originImgUrl
          } else {
            this.formData.originImgUrl = ''
          }
        }
        this.isChange = false
      } else {
        this.editor.undo()
      }
    },
    processedImgUrl() {
      if (this.formData.processedImgUrl) {
        this.editor2.undo()
        if (this.isChange2) {
          this.editor2.dangerouslyInsertHtml(
            `<img src='${this.formData.processedImgUrl}' style='width:356px;height:auto' />`
          )
        } else {
          this.editor2.clear()
          if (this.curRow.processedImgUrl) {
            this.formData.processedImgUrl = this.curRow.processedImgUrl
          } else {
            this.formData.processedImgUrl = ''
          }
        }
        this.isChange2 = false
      } else {
        this.editor2.undo()
      }
    }
  },
  methods: {
    closed() {
      const self = this
      this.editorShow = false
      if (this.isSaved || !this.isEdit) {
        if (this.isForm && !this.isSaved && typeof this.oldFormData == 'object') {
          this.inspectFormData()
        } else {
          self.exit()
        }
      } else {
        this.inspectFormData()
      }
    },
    handleImgClick(e) {
      if (e.target.src == 0) return
      if (this.$refs.videoModal) this.$refs.videoModal.open(e.target.src)
    },
    textAreaChange() {
      this.formData.originContent = this.textAreaList.find(item => item.id == this.formData.textAreaId).originalText
      this.formData.processedContent = this.textAreaList
        .find(item => item.id == this.formData.textAreaId)
        .showText.replaceAll('\n ', '\n')
    },
    async chapterChange() {
      this.selectLoading = true
      const res = await v1getAction(
        '/textarea/chapter/' + this.chapterList.find(c => c.sequenceNo == this.formData.chapterNo).chapterId
      )
      this.selectLoading = false
      if (res.code == 200) {
        this.textAreaList = []
        res.data.forEach(item => {
          item.textAreas.forEach(t => {
            this.textAreaList.push({
              ...t,
              label:
                '【' +
                item.pageSortOrderId +
                '-' +
                t.sequenceNo +
                '】' +
                (t.showText ? t.showText.replaceAll('\n ', '\n') : '')
            })
          })
        })
        this.formData.textAreaId = this.textAreaList[0].id
        this.formData.originContent = this.textAreaList[0].originalText
        this.formData.processedContent = this.textAreaList[0].showText.replaceAll('\n ', '\n')
      }
    },
    handleAfter() {},
    async handleUploadChange(info) {
      await this.beforeUpload(info.file.originFileObj)
      let formData = new FormData()
      for (let key in this.ossUploadData) {
        formData.append(key, this.ossUploadData[key])
      }
      formData.append('file', info.file.originFileObj)
      postAction(this.ossUploadData.host, formData).then(rest => {
        let params = this.getSaveFileParams(info.file.originFileObj)
        this.formData.originImgUrl = params.filePath
        this.originImgUrl = ''
        this.isChange = true
        this.wangeditorLoading = false
        this.$refs.form.validate()
        this.$forceUpdate()
      })
      return false
    },
    async handleUploadChange1(info) {
      await this.beforeUpload(info.file.originFileObj)
      let formData = new FormData()
      for (let key in this.ossUploadData) {
        formData.append(key, this.ossUploadData[key])
      }
      formData.append('file', info.file.originFileObj)
      postAction(this.ossUploadData.host, formData).then(rest => {
        let params = this.getSaveFileParams(info.file.originFileObj)
        this.formData.processedImgUrl = params.filePath
        this.processedImgUrl = ''
        this.isChange = true
        this.wangeditorLoading = false
        this.$refs.form.validate()
        this.$forceUpdate()
      })
      return false
    },
    handleUpload(options) {
      return
    },
    initForm() {
      this.reCurRow = {}
      this.formData = {}
      if (this.editor) this.editor.destroy()
      if (this.editor2) this.editor2.destroy()
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    initE() {
      let that = this
      this.editorConfig.MENU_CONF['uploadImage'] = {
        // 自定义上传
        async customUpload(file, insertFn) {
          that.wangeditorLoading = true
          that
            .tokenAxios({
              url: '/oss',
              method: 'get'
            })
            .then(res => {
              let policy = {
                policy: res.data.policy,
                signature: res.data.signature,
                ossaccessKeyId: res.data.accessid,
                dir: res.data.dir,
                host: res.data.host,
                key: `${res.data.dir}${that.getGuid()}_${file.name.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\w]/g, '')}`
              }
              that.ossUploadData = policy
              that.ossFilePolicy[file.name] = policy
              let formData = new FormData()
              for (let key in policy) {
                formData.append(key, policy[key])
              }
              formData.append('file', file)
              postAction(policy.host, formData).then(rest => {
                console.log('成功OSS', policy.host)
                let params = that.getSaveFileParams(file)
                that.formData.originImgUrl = params.filePath
                that.editor.undo()
                /*                 that.editor.dangerouslyInsertHtml(
                  `<img src='${that.formData.originImgUrl}' style='width:356px;height:auto' />`
                ) */
                that.isChange = true
                /* insertFn(policy.host, alt, href) */
                that.wangeditorLoading = false
                that.$forceUpdate()
              })
            })
        }
      }
    },
    initE2() {
      let that = this
      this.editorConfig2.MENU_CONF['uploadImage'] = {
        // 自定义上传
        async customUpload(file, insertFn) {
          that.wangeditorLoading2 = true
          that
            .tokenAxios({
              url: '/oss',
              method: 'get'
            })
            .then(res => {
              let policy = {
                policy: res.data.policy,
                signature: res.data.signature,
                ossaccessKeyId: res.data.accessid,
                dir: res.data.dir,
                host: res.data.host,
                key: `${res.data.dir}${that.getGuid()}_${file.name.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\w]/g, '')}`
              }
              that.ossUploadData = policy
              that.ossFilePolicy[file.name] = policy
              let formData = new FormData()
              for (let key in policy) {
                formData.append(key, policy[key])
              }
              formData.append('file', file)
              postAction(policy.host, formData).then(rest => {
                console.log('成功OSS', policy.host)
                let params = that.getSaveFileParams(file)
                that.formData.processedImgUrl = params.filePath
                that.editor2.undo()
                /*                 that.editor2.dangerouslyInsertHtml(
                  `<img src='${that.formData.processedImgUrl}' style='width:356px;height:auto' />`
                ) */
                that.isChange2 = true
                /* insertFn(policy.host, alt, href) */
                that.wangeditorLoading2 = false
                that.$forceUpdate()
              })
            })
        }
      }
    },
    customPaste(editor, event, callback) {
      console.log('ClipboardEvent 粘贴事件对象', event)
      // const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
      // const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
      // const rtf = event.clipboardData.getData('text/rtf') // 获取 rtf 数据（如从 word wsp 复制粘贴）

      // 自定义插入内容
      /* editor.insertText('xxx') */

      // 返回 false ，阻止默认粘贴行为
      let items = event.clipboardData && event.clipboardData.items
      let file = null
      if (items && items.length) {
        // 检索剪切板items
        if (items[0].kind == 'file') {
          file = items[0].getAsFile()
          console.log(file)
          this.wangeditorLoading = true
          this.tokenAxios({
            url: '/oss',
            method: 'get'
          }).then(res => {
            let policy = {
              policy: res.data.policy,
              signature: res.data.signature,
              ossaccessKeyId: res.data.accessid,
              dir: res.data.dir,
              host: res.data.host,
              key: `${res.data.dir}${this.getGuid()}_${file.name.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\w]/g, '')}`
            }
            this.ossUploadData = policy
            this.ossFilePolicy[file.name] = policy
            let formData = new FormData()
            for (let key in policy) {
              formData.append(key, policy[key])
            }
            formData.append('file', file)
            postAction(policy.host, formData).then(rest => {
              console.log('成功OSS', policy.host)
              let params = this.getSaveFileParams(file)
              this.formData.originImgUrl = params.filePath
              console.log(this.formData.originImgUrl)
              this.editor.undo()
              /*               this.editor.dangerouslyInsertHtml(
                `<img src='${this.formData.originImgUrl}' style='width:356px;height:auto' />`
              ) */
              this.isChange = true
              this.wangeditorLoading = false
            })
          })
        } else {
          this.$message.error('粘贴内容不是文件内容，请重新复制后粘贴')
        }
      }
      event.preventDefault()
      callback(false) // 返回值（注意，vue 事件的返回值，不能用 return）

      // 返回 true ，继续默认的粘贴行为
      // callback(true)
    },
    customPaste2(editor, event, callback) {
      console.log('ClipboardEvent 粘贴事件对象', event)
      // const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
      // const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
      // const rtf = event.clipboardData.getData('text/rtf') // 获取 rtf 数据（如从 word wsp 复制粘贴）

      // 自定义插入内容
      /* editor.insertText('xxx') */

      // 返回 false ，阻止默认粘贴行为
      let items = event.clipboardData && event.clipboardData.items
      let file = null
      if (items && items.length) {
        // 检索剪切板items
        if (items[0].kind == 'file') {
          file = items[0].getAsFile()
          console.log(file)
          this.wangeditorLoading2 = true
          this.tokenAxios({
            url: '/oss',
            method: 'get'
          }).then(res => {
            let policy = {
              policy: res.data.policy,
              signature: res.data.signature,
              ossaccessKeyId: res.data.accessid,
              dir: res.data.dir,
              host: res.data.host,
              key: `${res.data.dir}${this.getGuid()}_${file.name.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\w]/g, '')}`
            }
            this.ossUploadData = policy
            this.ossFilePolicy[file.name] = policy
            let formData = new FormData()
            for (let key in policy) {
              formData.append(key, policy[key])
            }
            formData.append('file', file)
            postAction(policy.host, formData).then(rest => {
              console.log('成功OSS', policy.host)
              let params = this.getSaveFileParams(file)
              this.formData.processedImgUrl = params.filePath
              this.editor2.undo()
              /*               this.editor2.dangerouslyInsertHtml(
                `<img src='${this.formData.processedImgUrl}' style='width:356px;height:auto' />`
              ) */
              this.isChange2 = true
              this.wangeditorLoading2 = false
            })
          })
        } else {
          this.$message.error('粘贴内容不是文件内容，请重新复制后粘贴')
        }
      }
      event.preventDefault()
      callback(false) // 返回值（注意，vue 事件的返回值，不能用 return）

      // 返回 true ，继续默认的粘贴行为
      // callback(true)
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      if (this.formData.originImgUrl) {
        this.editor.clear()
        this.isChange = true
        /*         this.editor.dangerouslyInsertHtml(`<img src='${this.formData.originImgUrl}' style='width:356px;height:auto' />`) */
        this.editor.updateView()
      }
    },
    onCreated2(editor) {
      this.editor2 = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      if (this.formData.processedImgUrl) {
        this.editor2.clear()
        this.isChange2 = true
        /*         this.editor2.dangerouslyInsertHtml(
          `<img src='${this.formData.processedImgUrl}' style='width:356px;height:auto' />`
        ) */
        this.editor2.updateView()
      }
    },
    async show() {
      console.log('显示...', { 'this.curRow': this.curRow })
      this.editorShow = true
      this.initE()
      this.initE2()
      this.selectLoading = true
      const res = await getAction('/chapter/production/' + this.parent.productionId)
      if (res.code == 200) {
        this.chapterList = res.data
        if (!this.chapterList.find(c => c.sequenceNo == this.curRow.chapterNo)) {
          this.selectLoading = false
          return
        }
        const rest = await v1getAction(
          '/textarea/chapter/' + this.chapterList.find(c => c.sequenceNo == this.curRow.chapterNo).chapterId
        )
        this.selectLoading = false
        if (rest.code == 200) {
          this.textAreaList = []
          rest.data.forEach(item => {
            item.textAreas.forEach(t => {
              this.textAreaList.push({
                ...t,
                label:
                  '【' +
                  item.pageSortOrderId +
                  '-' +
                  t.sequenceNo +
                  '】' +
                  (t.showText ? t.showText.replaceAll('\n ', '\n') : '')
              })
            })
          })
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    edit() {
      this.isEdit = true
      this.isSaved = false
      console.log('编辑...')
      this.formData = this.curRow
      console.log({ 'this.formData': this.formData })
      this.oldFormData = JSON.parse(JSON.stringify(this.formData))
      this.startEdit()
    },
    save() {
      this.btnLoading = true
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.chapterList.every(chapter => chapter.sequenceNo != this.formData.chapterNo)) {
            this.$message.error('章节已删除')
            this.btnLoading = false
            return
          }
          if (!this.isEdit) {
            this.formData.type = this.addType
            this.formData.productionId = this.parent.id
            const res = await postAction('/backgroundBook/create', this.formData)
            if (res.code == 200) {
              this.$message.success('新建成功')
              this.isSaved = true
              this.btnLoading = false
              this.closed()
            } else {
              this.btnLoading = false
              this.$message.error(res.msg || res.message)
            }
          } else {
            this.formData.productionId = this.parent.id
            const res = await postAction('/backgroundBook/update', this.formData)
            if (res.code == 200) {
              this.$message.success('保存成功')
              this.isSaved = true
              this.btnLoading = false
              this.closed()
            } else {
              this.btnLoading = false
              this.$message.error(res.msg || res.message)
            }
          }
        } else {
          return false
        }
      })
    },
    clearUrl() {
      this.formData.originImgUrl = ''
      this.$forceUpdate()
    },
    clearUrl2() {
      this.formData.processedImgUrl = ''
      this.$forceUpdate()
    }
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>

<style lang="less" scoped>
/* .w-e-text-container {
  height: 240px !important; 
}
.img-editor {
  border: 1px solid gainsboro;
  transition: 0.3s all;
  border-radius: 5px;
  &:hover {
    border-color: var(--theme-color);
  }
}
/deep/ .w-e-text-placeholder {
  line-height: 17px !important;
} */
/deep/ .img-editor {
  border: 1px solid gainsboro;
  transition: 0.3s all;
  margin-top: 15px;
  border-radius: 4px;
  transition: all 0.3s;
  &:hover {
    border: 1px solid var(--theme-color);
  }
  p {
    margin: 0 !important;
    height: 32px;
    padding-top: 5px !important;
  }
}
/deep/ .w-e-text-placeholder {
  line-height: 1px !important;
  font-style: normal;
  color: rgba(0, 0, 0, 0.35);
}
/deep/ .w-e-text-container {
  border-radius: 4px !important;
}
.ant-upload .anticon-close-circle {
  position: absolute;
  top: 0;
  right: -16px;
  transition: 0.3s all;
  &:hover {
    color: var(--theme-color);
  }
}
</style>
