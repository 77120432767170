var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        title: _vm.setTitle,
        show: _vm.open,
        isForm: _vm.isForm,
        maskClosable: false,
        "destroy-on-close": "",
        permission:
          _vm.curRow.type == "特殊字体" &&
          _vm.userInfo.positions &&
          _vm.userInfo.positions.some(
            (item) =>
              item.name == "翻译" || item.name == "校对" || item.name == "终审"
          )
            ? "production:termtemplate:back:noedit"
            : "production:termtemplate:back:edit",
        btnLoading: _vm.btnLoading,
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "" }, slot: "default" },
        [
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "章节序号" } },
            [_vm._v(_vm._s(_vm.curRow.chapterNo))]
          ),
          _vm.curRow.type != "特殊字体"
            ? _c("h4", { staticStyle: { "font-weight": "700" } }, [
                _vm._v("翻译、校对、终审填写："),
              ])
            : _vm._e(),
          _vm.curRow.type != "特殊字体"
            ? _c(
                "info-item",
                { attrs: { "label-width": _vm.labelWidth, label: "文本框" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.textAreaList.length && _vm.curRow.textAreaId
                        ? _vm.textAreaList.find(
                            (item) => item.id == _vm.curRow.textAreaId
                          )
                          ? _vm.textAreaList.find(
                              (item) => item.id == _vm.curRow.textAreaId
                            ).label
                          : ""
                        : ""
                    )
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "原文图片" } },
            [
              _c("div", [
                _vm.curRow.originImgUrl
                  ? _c("img", {
                      staticStyle: {
                        "max-width": "100%",
                        "max-height": "180px",
                        cursor: "pointer",
                      },
                      attrs: { src: _vm.curRow.originImgUrl, alt: "avatar" },
                      on: { click: _vm.handleImgClick },
                    })
                  : _vm._e(),
              ]),
            ]
          ),
          _vm.curRow.type != "特殊字体"
            ? _c(
                "info-item",
                {
                  staticStyle: {
                    "white-space": "pre-wrap",
                    "word-wrap": "break-word",
                  },
                  attrs: { "label-width": _vm.labelWidth, label: "原文" },
                },
                [_vm._v(_vm._s(_vm.curRow.originContent))]
              )
            : _vm._e(),
          _vm.curRow.type != "特殊字体"
            ? _c(
                "info-item",
                {
                  staticStyle: {
                    "white-space": "pre-wrap",
                    "word-wrap": "break-word",
                  },
                  attrs: { "label-width": _vm.labelWidth, label: "译文" },
                },
                [_vm._v(_vm._s(_vm.curRow.processedContent))]
              )
            : _vm._e(),
          _vm.curRow.type != "特殊字体"
            ? _c("h4", { staticStyle: { "font-weight": "700" } }, [
                _vm._v("制作、监制填写："),
              ])
            : _vm._e(),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "文件名" } },
            [_vm._v(_vm._s(_vm.curRow.fileName))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "制作后图片" } },
            [
              _c("div", [
                _vm.curRow.processedImgUrl
                  ? _c("img", {
                      staticStyle: {
                        "max-width": "100%",
                        "max-height": "180px",
                        cursor: "pointer",
                      },
                      attrs: { src: _vm.curRow.processedImgUrl, alt: "avatar" },
                      on: { click: _vm.handleImgClick },
                    })
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "字体" } },
            [_vm._v(_vm._s(_vm.curRow.typeface))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { style: _vm.backCss, attrs: { slot: "form" }, slot: "form" },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "form",
                staticStyle: { "padding-bottom": "65px" },
                attrs: { model: _vm.formData, rules: _vm.rules },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "章节序号",
                    prop: "chapterNo",
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        disabled:
                          (_vm.userInfo.positions &&
                            _vm.userInfo.positions.some(
                              (item) =>
                                item.name == "翻译" ||
                                item.name == "校对" ||
                                item.name == "终审"
                            )) ||
                          _vm.isAdmin ||
                          _vm.parent.isTongChou ||
                          _vm.addType == "特殊字体"
                            ? false
                            : true,
                        "show-search": "",
                        placeholder: "请选择章节",
                      },
                      on: { change: _vm.chapterChange },
                      model: {
                        value: _vm.formData.chapterNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "chapterNo", $$v)
                        },
                        expression: "formData.chapterNo",
                      },
                    },
                    [
                      _vm._l(_vm.chapterList, function (chapter) {
                        return _c(
                          "a-select-option",
                          {
                            key: chapter.sequenceNo,
                            attrs: { value: chapter.sequenceNo },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(chapter.sequenceNo) +
                                "\n          "
                            ),
                          ]
                        )
                      }),
                      _vm.isEdit &&
                      _vm.chapterList.every(
                        (chapter) =>
                          chapter.sequenceNo != _vm.formData.chapterNo
                      ) &&
                      _vm.formData.chapterNo
                        ? _c(
                            "a-select-option",
                            { attrs: { value: _vm.formData.chapterNo } },
                            [_vm._v("\n            章节已删除\n          ")]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              [
                _vm.formData.type == "背景文字" || _vm.addType == "背景文字"
                  ? _c("h4", { staticStyle: { "font-weight": "700" } }, [
                      _vm._v("\n          翻译、校对、终审填写：\n        "),
                    ])
                  : _vm._e(),
                _vm.formData.type == "背景文字" || _vm.addType == "背景文字"
                  ? _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          labelCol: _vm.labelCol,
                          wrapperCol: _vm.wrapperCol,
                          label: "文本框",
                          prop: "chapterNo",
                        },
                      },
                      [
                        _c(
                          "a-spin",
                          { attrs: { spinning: _vm.selectLoading } },
                          [
                            _c(
                              "a-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  dropdownClassName: "textArea-select",
                                  disabled:
                                    _vm.userInfo.positions &&
                                    _vm.userInfo.positions.some(
                                      (item) =>
                                        item.name == "制作" ||
                                        item.name == "监制"
                                    ),
                                  "show-search": "",
                                  "option-filter-prop": "label",
                                  placeholder: "请选择文本框",
                                },
                                on: { change: _vm.textAreaChange },
                                model: {
                                  value: _vm.formData.textAreaId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "textAreaId", $$v)
                                  },
                                  expression: "formData.textAreaId",
                                },
                              },
                              _vm._l(_vm.textAreaList, function (textArea) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: textArea.id,
                                    attrs: {
                                      label: textArea.label,
                                      value: textArea.id,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(textArea.label) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "a-form-model-item",
                  {
                    attrs: {
                      labelCol: _vm.labelCol,
                      wrapperCol: _vm.wrapperCol,
                      label: "原文图片",
                      prop: "chapterNo",
                    },
                  },
                  [
                    _c(
                      "a-spin",
                      { attrs: { spinning: _vm.wangeditorLoading } },
                      [
                        _c(
                          "a-upload-dragger",
                          {
                            attrs: {
                              name: "file",
                              accept: ".jpg,.png",
                              showUploadList: false,
                              action: _vm.ossUploadUrl,
                              disabled:
                                _vm.userInfo.positions &&
                                _vm.userInfo.positions.some(
                                  (item) =>
                                    item.name == "制作" || item.name == "监制"
                                ) &&
                                (_vm.addType == "背景文字" ||
                                  _vm.formData.type == "背景文字"),
                              data: _vm.ossUploadData,
                              "custom-request": _vm.handleUpload,
                            },
                            on: { change: _vm.handleUploadChange },
                          },
                          [
                            !_vm.formData.originImgUrl
                              ? _c("div", [
                                  _c(
                                    "p",
                                    { staticClass: "ant-upload-drag-icon" },
                                    [
                                      _c("a-icon", {
                                        attrs: { type: "inbox" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("p", { staticClass: "ant-upload-text" }, [
                                    _vm._v(
                                      "\n                  拖拽或点击上传\n                "
                                    ),
                                  ]),
                                  _c("p", { staticClass: "ant-upload-hint" }, [
                                    _vm._v(
                                      "\n                  支持JPG、PNG\n                "
                                    ),
                                  ]),
                                ])
                              : _c("img", {
                                  staticStyle: {
                                    "max-width": "300px",
                                    "max-height": "117px",
                                  },
                                  attrs: {
                                    src: _vm.formData.originImgUrl,
                                    alt: "",
                                  },
                                }),
                            _vm.formData.originImgUrl &&
                            ((_vm.userInfo.positions &&
                              _vm.userInfo.positions.every(
                                (item) =>
                                  item.name != "制作" && item.name != "监制"
                              )) ||
                              _vm.addType == "特殊字体" ||
                              _vm.formData.type == "特殊字体")
                              ? _c("a-icon", {
                                  attrs: { type: "close-circle" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.clearUrl.apply(null, arguments)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.editorShow &&
                        ((_vm.userInfo.positions &&
                          _vm.userInfo.positions.every(
                            (item) => item.name != "制作" && item.name != "监制"
                          )) ||
                          _vm.addType == "特殊字体" ||
                          _vm.formData.type == "特殊字体")
                          ? _c("Editor", {
                              ref: "editor",
                              staticClass: "img-editor",
                              attrs: {
                                defaultConfig: _vm.editorConfig,
                                mode: _vm.mode,
                              },
                              on: {
                                onCreated: _vm.onCreated,
                                customPaste: _vm.customPaste,
                              },
                              model: {
                                value: _vm.originImgUrl,
                                callback: function ($$v) {
                                  _vm.originImgUrl = $$v
                                },
                                expression: "originImgUrl",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.formData.type == "背景文字" || _vm.addType == "背景文字"
                  ? _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          labelCol: _vm.labelCol,
                          wrapperCol: _vm.wrapperCol,
                          label: "原文",
                          prop: "originContent",
                        },
                      },
                      [
                        _c("a-textarea", {
                          attrs: { disabled: true, rows: 5 },
                          model: {
                            value: _vm.formData.originContent,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "originContent", $$v)
                            },
                            expression: "formData.originContent",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formData.type == "背景文字" || _vm.addType == "背景文字"
                  ? _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          labelCol: _vm.labelCol,
                          wrapperCol: _vm.wrapperCol,
                          label: "译文",
                          prop: "processedContent",
                        },
                      },
                      [
                        _c("a-textarea", {
                          attrs: { disabled: true, rows: 5 },
                          model: {
                            value: _vm.formData.processedContent,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "processedContent", $$v)
                            },
                            expression: "formData.processedContent",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              (_vm.userInfo.positions &&
                _vm.userInfo.positions.some(
                  (item) => item.name == "制作" || item.name == "监制"
                )) ||
              _vm.isAdmin ||
              _vm.parent.isTongChou
                ? [
                    _vm.formData.type == "背景文字" || _vm.addType == "背景文字"
                      ? _c("h4", { staticStyle: { "font-weight": "700" } }, [
                          _vm._v("制作、监制填写："),
                        ])
                      : _vm._e(),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          labelCol: _vm.labelCol,
                          wrapperCol: _vm.wrapperCol,
                          label: "文件名",
                          prop: "fileName",
                        },
                      },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入文件名" },
                          model: {
                            value: _vm.formData.fileName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "fileName", $$v)
                            },
                            expression: "formData.fileName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          labelCol: _vm.labelCol,
                          wrapperCol: _vm.wrapperCol,
                          label: "制作后图片",
                          prop: "chapterNo",
                        },
                      },
                      [
                        _c(
                          "a-spin",
                          { attrs: { spinning: _vm.wangeditorLoading2 } },
                          [
                            _c(
                              "a-upload-dragger",
                              {
                                attrs: {
                                  name: "file",
                                  accept: ".jpg,.png",
                                  showUploadList: false,
                                  action: _vm.ossUploadUrl,
                                  data: _vm.ossUploadData,
                                  "custom-request": _vm.handleUpload,
                                },
                                on: { change: _vm.handleUploadChange1 },
                              },
                              [
                                !_vm.formData.processedImgUrl
                                  ? _c("div", [
                                      _c(
                                        "p",
                                        { staticClass: "ant-upload-drag-icon" },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "inbox" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "ant-upload-text" },
                                        [
                                          _vm._v(
                                            "\n                  拖拽或点击上传\n                "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "ant-upload-hint" },
                                        [
                                          _vm._v(
                                            "\n                  支持JPG、PNG\n                "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _c("img", {
                                      staticStyle: {
                                        "max-width": "300px",
                                        "max-height": "117px",
                                      },
                                      attrs: {
                                        src: _vm.formData.processedImgUrl,
                                        alt: "",
                                      },
                                    }),
                                _vm.formData.processedImgUrl
                                  ? _c("a-icon", {
                                      attrs: { type: "close-circle" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.clearUrl2.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.editorShow
                              ? _c("Editor", {
                                  ref: "editor",
                                  staticClass: "img-editor",
                                  attrs: {
                                    defaultConfig: _vm.editorConfig2,
                                    mode: _vm.mode,
                                  },
                                  on: {
                                    onCreated: _vm.onCreated2,
                                    customPaste: _vm.customPaste2,
                                  },
                                  model: {
                                    value: _vm.processedImgUrl,
                                    callback: function ($$v) {
                                      _vm.processedImgUrl = $$v
                                    },
                                    expression: "processedImgUrl",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          labelCol: _vm.labelCol,
                          wrapperCol: _vm.wrapperCol,
                          label: "字体",
                          prop: "tyoeface",
                        },
                      },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "字体" },
                          model: {
                            value: _vm.formData.typeface,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "typeface", $$v)
                            },
                            expression: "formData.typeface",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("playvideo-modal", { ref: "videoModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }